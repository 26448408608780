import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { IDynamicContent } from "../helpers/types";
import TopHeaderBanner from "./TopHeaderBanner";

/** Render banners with location: "Top Header Banner - Home" */
export default () => {
  // https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
  const data = useStaticQuery(graphql`
    query {
      allContentfulCivicAllianceDynamicContent(filter: {location: {eq: "Top Header Banner - Home"} }) {
        nodes {
          title
          displayTitle
          content {
            raw
          }
          location
          backgroundColor
          sortOrder
          startDate
          endDate
        }
      }
    }
  `);
  const nodes: Array<IDynamicContent> = data.allContentfulCivicAllianceDynamicContent.nodes;

  if(nodes.length === 0){
    return null;
  }

  return (
   <>
    {nodes.map((content, idx)=>(
      <TopHeaderBanner key={idx} content={content}  />
    ))}
   </>
  )
}