import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import Section from "../components/Section";
import CompanyCounter from "../components/CompanyCounter";
import { Grid, Image } from "semantic-ui-react";
import { 
  BorderDiv,
  LogoHiddenLabel,
  ResponsiveGrid,
  SectionH1,
  SectionH2,
  SectionH3Sub,
  SectionH4,
  SectionP,
  PartnerLogo
} from "../helpers/styledComponents";
import { JoinCivicAllianceButton, ButtonLink } from "../components/Buttons";
import GrayUSAFlag from "../../static/images/american-flag-gray.jpg";
import Pledge from "../../static/images/pledge.png";
import CAAFoundation from "../../static/images/caafoundation.png";
import DemocracyNow from "../../static/images/democracyworks.png";
import Meteorite from "../../static/images/meteorite-logo.png";
import TopHeaderBannerHome from "../components/TopHeaderBannerHome";
import { IDynamicContent } from "../helpers/types";

const HeaderContentH1 = styled.h1`
  font-size: 3em;
  font-weight: 600;
  line-height: 1.125;
  
  @media (max-width: 769px) {
    text-align: center;
    font-size: 1.3em;
  }
`;

const HeaderDiv = styled.div`
  @media (max-width: 767px) {
    text-align: center;
  }
`;

export const LogoGrid = styled(ResponsiveGrid)`
  &&&& {
    margin: -.75em;
    @media screen and (min-width: 1024px) {
      .column {
          width: 25%;
      }
    }
  }
`;

const StyledGridRowHeader = styled(Grid.Row)`
  @media (min-width: 768px) {
    margin-bottom: 1.5em;
  }
`;

const StyledGridRow = styled(Grid.Row)`
  @media (max-width: 767px) {
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
  }
`;

const HeaderContent = (
  <HeaderDiv>
    <HeaderContentH1>      
      We are the Civic Alliance, <br />
      a nonpartisan coalition of businesses <br />
      united by our commitment  <br />
      to a thriving democracy. <br />
    </HeaderContentH1>
    <JoinCivicAllianceButton short mode="light" larger style={{ marginTop: "1.5rem" }} />
  </HeaderDiv>
);

interface ICivicAllianceStats {
  companies: number; 
  employeesReached: number;
}

export interface IAllianceCompany {
  id: string;
  name: string;
  commitments: Array<string>;
  logoSize?: string;
  logo: {
    file: {
      fileName: string;
      url: string;
    }
  }
}

export interface IContenfulData {
  allContentfulCivicAllianceCompany: {
    nodes: Array<IAllianceCompany>;
  };
  contentfulCivicAllianceStats: ICivicAllianceStats;
  allContentfulCivicAllianceDynamicContent: {
    nodes: Array<IDynamicContent>
  };
}

interface IProps {
  data: IContenfulData;
}


export default ({ data }: IProps) => {
  const homeBannerContent = data.allContentfulCivicAllianceDynamicContent.nodes.filter( content => content.location === "Top Header Banner - Home" );

  return (
    <Layout 
      mode="dark"
      caaBannerContent={ homeBannerContent.length > 0 ? <TopHeaderBannerHome /> : undefined }
      headerContent={ HeaderContent }
      title="Civic Alliance"
    >
      <Section mode="light" largest>
        <SectionH1>We stand together in support of fair and transparent elections, in which voting is safe and accessible. And we use the combined influence of our platforms to empower every American to use their voice. </SectionH1>
      </Section> 

      <Section mode="dark" largest>
        <SectionH1>
          Our companies rely on a strong, responsive democracy. As business leaders, we honor the trust our employees place in us with a commitment to provide them with the education and support they need to take civic action in our communities.
        </SectionH1>
      </Section> 
      
      <Section mode="light" largest>
        <SectionH1>We believe a healthy democracy must be continually nurtured. This work does not begin or end on Election Day. The Civic Alliance activates corporate America year-round to create a dynamic culture of corporate civic engagement. </SectionH1>
      </Section> 
      
      <Section mode="dark" large backgroundImage={GrayUSAFlag} >
        <Grid>
          <StyledGridRowHeader centered>
            <SectionH2>As a nonpartisan alliance, we:</SectionH2>
          </StyledGridRowHeader>
          <StyledGridRow centered>
            <Grid columns='equal' style={{padding: 0, margin: "-.75rem -1.5rem .75rem" }} stackable>
              <Grid.Column style={{padding: ".75rem 1.5rem"}} stretched>
                <BorderDiv mode="dark" transparent style={{ padding: "2em 1em", fontSize: "1.25em", marginBottom: "0"}}>
                  <SectionP center style={{fontWeight: 700}}>
                    Empower our US employees with resources and workplace policies that enable civic participation.
                  </SectionP>
                </BorderDiv>
              </Grid.Column>
              <Grid.Column style={{padding: ".75rem 1.5rem"}} stretched>
                <BorderDiv mode="dark" transparent style={{ padding: "2em 1em", fontSize: "1.25em", marginBottom: "0" }}>
                  <SectionP center style={{fontWeight: 700}}>
                    Encourage our American audiences to vote and get involved in other civic activities.
                  </SectionP>
                </BorderDiv>
              </Grid.Column>
              <Grid.Column style={{padding: ".75rem 1.5rem"}} stretched>
                <BorderDiv mode="dark" transparent style={{ padding: "2em 1em", fontSize: "1.25em", marginBottom: "0" }}>
                  <SectionP center style={{fontWeight: 700}}>
                    Champion civic resiliency and protect our democracy.
                  </SectionP>
                </BorderDiv>
              </Grid.Column>
            </Grid>
          </StyledGridRow>
          <StyledGridRow centered style={{ paddingTop: ".5rem", paddingBottom: ".25rem" }}>
            <SectionH3Sub style={{ lineHeight: "1.4em", maxWidth: "none" }}>
              Our democracy is only as strong as our commitment to participate in it.
            </SectionH3Sub>
          </StyledGridRow>
          <Grid.Row centered style={{ paddingTop: "8px"}}>
            <JoinCivicAllianceButton mode="light" larger/>
          </Grid.Row>
        </Grid>
      </Section>

      <SectionH2 style={{marginTop: "3em", marginLeft: "1rem"}}>Civic Alliance Member Companies</SectionH2>

      <CompanyCounter
        style={{ paddingBottom: "100px"}}
        companies={data.contentfulCivicAllianceStats.companies} 
        employeesReached={data.contentfulCivicAllianceStats.employeesReached}
      />

      <Section mode="light" relaxedPadding style={{ marginBottom: "5px" }}>          
        <ResponsiveGrid centered negativeMargin>
          { data.allContentfulCivicAllianceCompany.nodes
              .filter(company => company.commitments.includes("Include Member on Homepage"))
              .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))
              .map(company => 
                <Grid.Column title={company.name} textAlign="center" verticalAlign="middle" key={company.id} style={{ padding: ".75rem" }}>
                  <LogoHiddenLabel>{company.name}</LogoHiddenLabel>
                  <PartnerLogo src={company.logo.file.url} logosize={company.logoSize}/>
                </Grid.Column>
              )
          }
        </ResponsiveGrid>
        <Section mode="light">
          <ButtonLink mode="dark" center larger to="/members"> See all member companies </ButtonLink>
        </Section>
      </Section>

      <Section mode="dark" style={{borderBottom: "1px solid black", padding: "5rem 0"}}>
        <Grid columns={2} relaxed="very" stackable>
          <Grid.Column mobile={16} tablet={16} computer={8} style={{ padding: ".75rem 1.5rem" }}>
            <Image src={Pledge} />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8} verticalAlign="top" style={{ padding: ".75rem 1.5rem" }}>
            <SectionH2 left>Civic Alliance Pledge</SectionH2>
            <SectionP>
              We believe the strength of the United States lies in its people, each with the power and the potential to shape the future of our country.
            </SectionP>
            <SectionP>
            Together, our companies have the platform and the reach to encourage every American to speak up, achieving the promise of their participation.
            </SectionP>
            <SectionP>
            Like all Americans, our companies have unique values. Yet we are united in envisioning a future where everyone is engaged in civics, starting with voting in every election.
            </SectionP>
          </Grid.Column>
          <Grid.Row centered style={{ paddingTop: "30px"}}>
            <JoinCivicAllianceButton mode="light" larger/>
          </Grid.Row>
        </Grid>
      </Section>

      <Section mode="light" extraMargin relaxedPadding>
        <SectionH2 style={{ marginBottom: "50px" }}>Civic Alliance Advising Partners</SectionH2>
        <ResponsiveGrid centered negativeMargin>
          { data.allContentfulCivicAllianceCompany.nodes
              .filter(company => company.commitments.includes("Advising Partner"))
              .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))
              .map(company => {
                return (
                  <Grid.Column title={company.name} textAlign="center" verticalAlign="middle" key={company.id} style={{ padding: ".75rem" }}>
                    <LogoHiddenLabel>{company.name}</LogoHiddenLabel>
                    <PartnerLogo src={company.logo.file.url} logosize={company.logoSize} />
                  </Grid.Column>
                );
              })
          }
        </ResponsiveGrid>
        <Section mode="light">
          <JoinCivicAllianceButton mode="dark" center larger/>
        </Section>
      </Section>

      <Section mode="light" small style={{ borderTop: "1px solid black", paddingTop: "50px" }}>
        <SectionH4 style={{ fontSize: "1.75em", marginLeft: "30px"}}>Civic Alliance Founding Organizations</SectionH4>
        <Grid columns={2} stackable>
          <Grid.Column verticalAlign="middle" textAlign="center" >
            <Image as="a" href="https://www.caa.com/foundation" target="_blank" src={CAAFoundation} style={{maxWidth: "200px", margin: "25px auto", width: "100%" }}/>
          </Grid.Column>
          <Grid.Column verticalAlign="middle" textAlign="center" >
            <Image as="a" href="https://www.democracy.works/" target="_blank" src={DemocracyNow} style={{maxWidth: "200px", margin: "25px auto", width: "100%"}}/>
          </Grid.Column>
        </Grid>
        <SectionH4 style={{ fontSize: "1.75em", marginLeft: "30px"}}>Powered by</SectionH4>
        <Grid columns={1}>
          <Grid.Row centered>
            <Image as="a" href="https://www.wearemeteorite.com/" target="_blank" src={Meteorite} style={{maxWidth: "200px", marginTop: "-25px", marginBottom: "25px", width: "100%"}}/>
          </Grid.Row>
        </Grid>
      </Section>

    </Layout>

  )
};

export const query = graphql`
  query {
    contentfulCivicAllianceStats {
      companies
      employeesReached
    }
    allContentfulCivicAllianceCompany(filter: {commitments: {in: ["Include Member on Homepage", "Advising Partner"]}, logo: {file: {fileName: {ne: null}}}}, sort: {fields: name}) {
      nodes {
        name
        id
        commitments
        logoSize
        logo {
          file {
            fileName
            url
          }
        }
      }
    }
    allContentfulCivicAllianceDynamicContent {
      nodes {
        title
        displayTitle
        content {
          raw
        }
        location
        backgroundColor
        sortOrder
        startDate
        endDate
      }
    }
  }
`;

